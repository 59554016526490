<template>
  <v-card :key="key">
    <v-app-bar flat color="primary">
      <v-toolbar-title class="text-h6 white--text pl-0">
        {{ $t("detail") }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        color="white"
        icon
        @click="$emit('closeEditModal')"
        :title="$t('Cancel')"
        id="btn-modal-cancel"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>
    <v-card-text class="pb-0" style="max-height: 60vh; overflow-y: auto">
      <v-list class="pt-3 pb-0" v-if="form">
        <ValidationObserver
          v-slot="{}"         
          ref="form"
        >
        <v-list-item>
          <v-list-item-content class="pb-0">
            <ng-label :options="getTextVariant(form)" label="Producto" v-model="form.product.name" v-if="form && form.product && form.product.name"></ng-label>            
          </v-list-item-content>
        </v-list-item>        
        <v-list-item>
          <v-list-item-content class="">
            <ng-select
              v-model="form.rule"
              dense
              label="rule"
              url="api/v1/list/rules"
              rules="required"
            ></ng-select>
          </v-list-item-content>
        </v-list-item>
        <v-list-item> 
          <v-list-item-content class="pb-0">
            <ng-select
              dense
              v-model="form.tax"
              label="tax"
              rules="required"
              url="api/v1/list/taxes"
              @input="calculateTotals(form)"
            ></ng-select>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="pb-0">
            <ng-number
              dense
              v-model="form.quantity"              
              :label="getLabelQuantiy(form)"
              :rules="{ 'max_value': form.quantity_available, 'required': true, min_value: 0.1 }"              
              @input="calculateTotals(form)"
              @keyup.enter="calculateTotals(form)"
            >
            </ng-number>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="pb-0">
            <ng-money
              hide-details
              v-model="form.item_amount"
              label="Precio Venta"
              rules="required"
              @input="calculateTotals(form)"
              @keyup.enter="calculateTotals(form)"
            ></ng-money>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="pb-0">
            <ng-select-options
              v-model="form.reverse_inventory"
              label="Reversar Inventario"
              :items="type_yes_no"
              rules="required"
            ></ng-select-options
          ></v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="pb-0">
            <ng-textarea
              v-model="form.observations"
              label="Observaciones"
              @change="
                () => {
                  this.form.observations = form.observations;
                }
              "
            ></ng-textarea
          ></v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="py-1">
            <v-row class="mt-0">
              <v-col cols="12" class="text-right my-0 pt-0">
                <span
                  v-if="form.total_amount"
                  class="title font-weight-regular"
                >
                  {{ $t("Total Detalle") }}:
                  {{ $filters.currency(form.total_amount, 0) }}</span
                >
                <span v-else class="title font-weight-regular">
                  {{ $t("Total Detalle") }}: $0
                </span>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        </ValidationObserver>

      </v-list>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        small
        :disabled="!isValid(form)"
        color="primary"
        @click="$emit('saveModalItem', form)"
        :title="$t('Guardar')"
        id="btn-detail-save-content"
      >
        <v-icon>mdi-check</v-icon>{{ $t("apply") }}
      </v-btn>
      <v-btn
        @click="$emit('closeEditModal')"
        :title="$t('cancel')"
        dark
        small
        color="grey darken-3"
        id="btn-detail-cancel"
      >
        <v-icon>mdi-cancel</v-icon>{{ $t("cancel") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style scoped>
.v-text-field.v-text-field__details{
  margin-bottom: 0 !important;
}
</style>
<script>
import DetailRowEdit from "./DetailRowEdit.vue";

export default {
  name: "DetailModalEdit",
  mixins: [DetailRowEdit],
  data() {
    return {
      key: 0,
      // form: { product: null },
      // totalAmountItemSelected: 0,
      // type_yes_no: [
      //   { id: "false", name: "No" },
      //   { id: "true", name: "Si" },
      // ],
    };
  },
  mounted() {
    
    // this.getProductsDocument();
  },
  props: ["item", "productsDocument"],
  methods:{
    loadFormItem(){
      this.form = JSON.parse(JSON.stringify(this.item));
    }
  }
};
</script>