<template>
    <table style="width: 100%" class="my-4">
      <tr>
        <td class="py-2" colspan="9">
          <v-row>
            <v-col cols="12" md="6" lg="3" class="pb-0">
              <ng-label :options="getTextVariant(form)" label="Producto" v-model="form.product.name" v-if="form && form.product && form.product.name"></ng-label>
            </v-col>
            <v-col cols="12" md="6" lg="5" class="pb-0">
              <ng-auto
                v-model="form.rule"
                label="rule"
                url="api/v1/list/rules"
                rules="required"
              ></ng-auto>
            </v-col>
          </v-row>
  
          <v-row class="mt-0">
            <v-col cols="12" md="3" lg="3" class="pb-0">
              <ng-select
                v-model="form.tax"              
                label="tax"
                url="api/v1/list/taxes"
                @input="calculateTotals(form)"
                rules="required"
              ></ng-select>
            </v-col>
            <v-col cols="12" md="3" lg="3" class="pb-0">            
              <ng-number
                v-model="form.quantity"              
                :label="getLabelQuantiy(form)"
                :rules="{ 'max_value': form.quantity_available, 'required': true, min_value: 0.1 }"
                @input="calculateTotals(form)"
                @keyup.enter="calculateTotals(form)"
              >
              </ng-number>            
            </v-col>
            <v-col cols="12" md="3" lg="3" class="pb-0">
              <ng-money
                v-model="form.item_amount"              
                label="Precio Venta"
                @input="calculateTotals(form)"
                rules="required"
              ></ng-money>
            </v-col>
  
            <v-col cols="12" md="3" lg="3" class="pb-0">
              <ng-select-options
                v-model="form.reverse_inventory"              
                label="Reversar Inventario"
                :items="type_yes_no"
                rules="required"
              ></ng-select-options>
            </v-col>
          </v-row>
  
          <v-row>
            <v-col cols="12" md="6" lg="12" class="py-0">
              <ng-textarea
                v-model="form.observations"
                hide-details
                label="Observaciones"
                @change="
                  () => {
                    this.item.observations = form.observations;
                  }
                "
              ></ng-textarea>
            </v-col>
          </v-row>
  
          <v-row class="mt-0">
            <v-col cols="12" class="text-right mt-1 mb-0">
              <span v-if="form.total_amount" class="title font-weight-regular">
                {{ $t("Total Detalle") }}:
                {{ $filters.currency(form.total_amount, 0) }}</span
              >
              <span v-else class="title font-weight-regular">
                {{ $t("Total Detalle") }}: $0
              </span>
            </v-col>
  
            <v-col
              cols="12"
              class="text-right container-detail-buttons mt-0 pt-0"
            >
              <v-btn
                @click="saveItemChanges(form)"
                outlined
                :disabled="!isValid(form)"
                small
                color="primary"
                id="btn-detail-save-content"
                :title="$t('apply')"
              >
                <v-icon>mdi-check</v-icon> {{ $t("apply") }}
              </v-btn>
  
              <v-btn
                @click="$emit('cancel', form)"
                outlined
                small
                color="grey darken-3"
                v-if="!form.is_new"
                id="btn-detail-cancel"
                class="ml-1"
                :title="$t('cancel')"
              >
                <v-icon>mdi-cancel</v-icon>{{ $t("cancel") }}
              </v-btn>
  
              <v-btn
                @click="$emit('delete', form)"
                small
                outlined
                color="error"
                id="btn-detail-delete"
                class="ml-1 white--text"
                :title="$t('delete')"
              >
                <v-icon>mdi-delete-outline</v-icon>{{ $t("delete") }}
              </v-btn>
            </v-col>
          </v-row>
        </td>
      </tr>
    </table>
  </template>
  
  <script>
  // import NgProductPurchase from "@/components/modules/Inventory/Product/NgProductPurchase.vue";
  
  export default {
    name: "DetailRowEdit",
    //   components: { NgProductPurchase },
    data() {
      return {
        form: { product: null },
        totalAmountItemSelected: 0,
        type_yes_no: [
          { id: "false", name: "No" },
          { id: "true", name: "Si" },
        ],
        rules: "required",      
      };
    },
    props: ["item", "products-invoice"],
    mounted() {
      this.form = this.item;
    },
    methods: {
  
      getTextVariant(form){
        let lbl = '';
        if(form.product_variant && form.product_variant.name){
          lbl += form.product_variant.name;
        }
        return lbl;
      },
      getLabelQuantiy(form){
        let lbl = 'Cantidad';
  
        if(form.product && form.product.unity && form.product.unity.name){
          lbl += " (" + form.product.unity.name + ")";
        }
        return lbl;
  
      },    
      isValid(item) {      
        if (!item) {
          return false;
        }
        if (!item.product || !item.product.id) {
          return false;
        }
        if (!item.tax || !item.tax.id) {
          return false;
        }
        if (!item.rule || !item.rule.id) {
          return false;
        }
        if (item.quantity == "" ||
          item.quantity == null ||
          item.quantity == 0 || (item.quantity > item.quantity_available)) {          
            return false;
            
        }
        if (
          item.item_amount == "" ||
          item.item_amount == null ||
          item.item_amount == 0
        ) {
          return false;
        }
        if (!item.reverse_inventory || !item.reverse_inventory.id) {
          return false;
        }
        return true;
      },
      saveItemChanges(item) {      
        if (this.isValid(item)) {
          item._editing = false;        
          this.$emit("save:item", item);
        }
      },
      compare(a, b) {
        if (a && b) {
          if (a.id && b.id && a.id === b.id) {
            return true;
          }
          if (a === b) {
            return true;
          }
          if (a.id == b) {
            return true;
          }
        }
        return false;
      },
      setDataDetail(data) {
        if (data) {
          this.item.tax = data.taxes[0];
          this.item.line_extension_amount = data.line_extension_amount;
          this.item.tax_amount = data.tax_amount;
          this.item.taxes = data.taxes;
          this.item.total_amount = data.total_amount;
          this.item.quantity = data.quantity;
          this.item.item_amount = data.item_amount;
          this.form.quantity = data.max_quantity;
          this.max_quantity = data.max_quantity;
          this.rules = "required|max_value:" + data.max_quantity;
          this.form.tax = data.taxes[0];
          this.calculateTotals(this.form);
        }
      },
      round(num) {
        let m = Number((Math.abs(num) * 100).toPrecision(15));
        return (Math.round(m) / 100) * Math.sign(num);
      },
      calculateBase(tax, price) {
        if (tax) {
          if (tax["percentage"] > 0) {
            let factorTax = 1 + tax["percentage"] / 100;
            return price / factorTax;
          }
        }
        return price;
      },
      calculateTaxes(tax, base) {
        if (tax) {
          if (tax["percentage"] > 0) {
            return (base * tax["percentage"]) / 100;
          }
        }
        return 0;
      },
      calculateTotals(item) {
        let total_amount_invoice = item.item_amount * item.quantity;
        item.taxes = [item.tax];
  
        item.line_extension_amount = this.calculateBase(
          item.tax,
          total_amount_invoice
        );
  
        let line_extension_roundOut = this.round(item.line_extension_amount);
        if (!item.discount_amount) {
          item.discount_amount = 0;
        }
        let subtotal_amount = item.line_extension_amount - item.discount_amount; //subtotal_con_descuento
        item.cost = item.line_extension_amount / item.quantity;
        let tax_amount = this.round(
          this.calculateTaxes(item.taxes[0], subtotal_amount)
        );
        if (item.tax) {
          item.tax["tax_amount"] = tax_amount;
          item.tax_amount = tax_amount;
          let subtotal_amount_roundOut = this.round(subtotal_amount);
          item.tax["taxable_amount"] = subtotal_amount_roundOut;
          item.total_amount = subtotal_amount_roundOut + tax_amount;
        }
        item.line_extension_amount = line_extension_roundOut;
      },
    },
  };
  </script>
  