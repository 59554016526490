
import _ from 'lodash';

export default {
	data() {
		return {
			errorDetails: false,
			productsDocument: [],
		}
	},
	mounted() {
		this.setRules();
	},
	computed: {
		productsAvailables() {
			let availables = [];
			for (const product of this.productsDocument) {
				let omit = false;
				for (const detail of this.form.details) {
					if (product.id == detail.product.id && detail._editing == false) {
						omit = true;
					}
				}
				if (omit == false) {
					availables.push(product);
				}
			}
			return availables;
		}
	},
	methods: {
		reset() {
			this.form = { details: [] }; //The details should be initialized as an empty array.
		},
		disabledCreate() {
			if (!this.hasDetails() || this.itemCurrentlyBeingEdited()) {
				return true;
			}
			return !this.hasChanges(); // || (this.form.details && this.form.details.length == 0)
		},
		disabledUpdate() {
			if (this.disabledCreate()) {
				return true;
			}
			return false;
		},
		resetDetails() {
			this.form.details = [];
		},
		allowUpdate() {
			if (this.item) {
				if (this.item.document_state && this.item.document_state.id == "draft") {
					return true;
				} else {
					return false;
				}
			}
			return true;
		},
		hasChanges() {
			let hasOld = JSON.stringify(this.form).hashCode();
			return hasOld !== this.hashValue;
		},
		hasDetails() {
			if (this.form.details && this.form.details.length > 0) {
				return true;
			}
			return false;
		},
		executeInProgress() {
			this.changeState('in_progress');
		},
		executeAnnulled() {
			this.changeState('annulled', 'enter_cancellation_reason', this.openDialogText);
		},
		executeFinalized() {
			this.changeState('finalized', 'want_approve_document', this.openConfirm);
		},
		executeDraft() {
			this.changeState('draft');
		},
		changeState(status, text = null, typeModal = this.openDialogText) {
			typeModal(
				this.$t(text)
			).then((response) => {
				if (response) {
					// console.log("change", this.urlSave, this.item.id);
					this.$http
						.post(
							this.urlSave + "/" + this.item.id + "/change-status",
							{ status, note: response },
							true,
							true
						)
						.then((response) => {
							if (response.success) {
								this.$nextTick(() => {
									this.item.document_state = response.item.document_state;
									this.item._actions = response.item._actions;
								});
							}
						});
				}
			});
		},

		prepareDetails(item) {
			if (item.details) {
				for (const d of item.details) {
					d._editing = false;
					d._deleted = false;
				}
			}
		},
		cancel() {
			this.$router.go(-1);
		},
		saveRowItem(item) {
			for (let i in this.form.details) {
				if (this.form.details[i].id == item.id) {
					this.form.details[i] = item;
					return;
				}
			}
			this.addItemToDetails(item);
		},
		addItemToDetails(item) {
			if (!this.form["details"]) {
				this.form["details"] = [];
			}
			this.form["details"].push(item);
		},
		loadForm(item) {
			this.form = { ...item };
		},
		hasChanged(oldValue, newValue) {
			if ((!oldValue && newValue) || (oldValue && !newValue)) {
				return true;
			}
			if (
				newValue &&
				newValue.id &&
				oldValue &&
				oldValue.id &&
				oldValue.id != newValue.id
			) {
				return true;
			}
			return !_.isEqual(oldValue, newValue);
		},
		can(actions, name) {
			if (name == "get") {
				return true;
			}
			if (actions && actions.length > 0 && actions.indexOf(name) >= 0) {
				return true;
			}
			return false;
		},
		executeUpdate() {
			const values = this.getValues();
			let id = this.$route.params.id;
			this.loading = true;
			if (this.item && this.item.id) {
				id = this.item.id;
			}
			if (id) {
				this.$http
					.put(this.urlSave + "/" + id, values, true, true)
					.then((response) => {
						this.$emit("change:item", response);
						this.loadForm(JSON.parse(JSON.stringify(response.item)));
						//this.moduleActions = response.module_actions;
						this.loading = false;
					})
					.catch((e) => {
						this.loading = false;
						console.log(e);
					});
			} else {
				this.$http
					.post(this.urlSave, values, true, true)
					.then((response) => {
						this.$emit("change:item", response);
						this.loadForm(JSON.parse(JSON.stringify(response.item)));
						//this.moduleActions = response.module_actions;
						this.loading = false;
					})
					.catch((e) => {
						this.loading = false;
						console.log(e);
					});
			}
		},
		executeCreate() {
			const values = this.getValues();
			//let id = this.$route.params.id;
			this.loading = true;
			const turn = this.$store.getters.turn;
			if (turn) {
				values["cash_register_turn"] = turn.id;
				values["cash_register"] = turn.bill_cash_register_id;
			}
			this.$http
				.post(this.urlSave, values, true, true)
				.then((response) => {
					//console.log(response)
					this.$emit("change:item", response);
					this.loadForm(JSON.parse(JSON.stringify(response.item)));
					//this.moduleActions = response.module_actions;
					this.loading = false;
				})
				.catch((e) => {
					this.loading = false;
					console.log(e);
				});
		},
		executeDelete() {
			this.apiDelete(
				this.urlGet + "/" + this.item.id,
				"Esta seguro que desea eliminar este registro?"
			).then(() => {
				this.$router.go(-1);
			});
		},
		apiDelete(url, confirm, params) {
			return new Promise((resolve, reject) => {
				this.openConfirm(this.$t(confirm)).then((response) => {
					if (response) {
						this.$http.delete(url, null, true, true).then((response) => {
							if (response.success) {
								resolve(true)
							} else {
								reject();
							}
						});
					}
				});
			})

		},
		disabledChangeState() {
			if (this.itemCurrentlyBeingEdited()) {
				return true;
			}
			return false;
		},
		itemCurrentlyBeingEdited() {
			if (this.form.details && this.form.details.length > 0) {
				for (let item of this.form.details) {
					if (item._editing) {
						return true;
					}
				}
			}
			return false;
		},
	}
}
