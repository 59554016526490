export default {
    data() {
        return {
            dialogEdit: false,
            currentItem: false,
            totals_document: { 
                total_base: 0,
                total_discounts: 0,
                subtotal: 0,
                total_taxes: 0,
                total_to_pay: 0,
                total_paid: 0,
                balance_to_pay: 0,
                balance_to_collect: 0,
                total_credit_notes: 0,
                total_debit_notes: 0,
                total_retentions: 0
            },
        };
    },
    props: [
        "items",
        "form",
        "disabled",
    ],
    methods: {
        isValid(item) {
            throw new Error('This method should validate whether the item is valid to be added to the details array.')
        },
        allowAddItem() {
            throw new Error('This method enables or disables the button to add items according to business rules. You should implement it in your detail view.')
        },
        itemCurrentlyBeingEdited(){
            if(this.form.details && this.form.details.length > 0){
                for(let item of this.form.details){
                    if(item._editing){
                        return true;
                    }
                }
            }
        },
        async addItem() {
            let v = this.isValid(this.currentItem);
            //console.log(v, this.currentItem)
            if (!this.currentItem || v) {
                //console.log('add item...')
                this.lastItem = this.currentItem;
                if (this.currentItem) {
                    this.currentItem._editing = false;
                }
                await this.createItem();          
                this.$emit('add:item', this.currentItem);              

            }
        },
        resetTotals(){
            this.totals_document = { 
                total_base: 0,
                total_discounts: 0,
                subtotal: 0,
                total_taxes: 0,
                total_to_pay: 0,
                total_paid: 0,
                balance_to_pay: 0,
                balance_to_collect: 0,
                total_credit_notes: 0,
                total_debit_notes: 0,
                total_retentions: 0
            }
        },        
        calculateDocumentTotals() {
            if(this.form.details && this.form.details.length > 0 ){
                this.resetTotals();
                for (let i in this.form.details) {
                    if(this.form.details[i].line_extension_amount){
                        this.totals_document.total_base += this.form.details[i].line_extension_amount;
                    }
                    if(this.form.details[i].discount_amount){
                        this.totals_document.total_discounts += this.form.details[i].discount_amount;
                    }
                    if(this.form.details[i].subtotal_amount){
                        this.totals_document.subtotal += this.form.details[i].subtotal_amount;
                    }
                    if(this.form.details[i].tax_amount){
                        this.totals_document.total_taxes += this.form.details[i].tax_amount;
                    }
                    if(this.form.details[i].total_amount){
                        this.totals_document.total_to_pay += this.form.details[i].total_amount;
                        this.totals_document.balance_to_collect += this.form.details[i].total_amount;
                    }
                }
                this.form.totals_document = this.totals_document;
            }            
        },        
        edit(item) {
            //console.log(item)
            if (this.currentItem) {
                if (!this.isValid(this.currentItem)) {
                    return;
                }
                this.currentItem._editing = false;
            }
            item._editing = true;
            this.currentItem = item; // JSON.parse(JSON.stringify(item));
        },
        deleteItem(item) {            
            this.currentItem = null;
            for (const i in this.items) {
                if (this.items[i].id == item.id) {
                    this.items.splice(i, 1);
                    this.$emit("input", this.items);
                }
            }
        },
        cancelEdit(item) {
            this.currentItem = null;
            for (const i in this.items) {
                if (this.items[i].id == item.id) {
                    this.items[i]._editing = false;
                    this.$emit("input", this.items);
                }
            }
        },
        isEqualObjectId(sub1, sub2) {
            if (!sub1 && !sub2) {
                return true;
            }
            if (sub1 && sub2 && sub1.id == sub2.id) {
                return true;
            }
            return false;
        },
        exists(item) {
            for (const it of this.items) {
                if (it.id == item.id) {
                    return true;
                }
            }
            return false;
        },
        async createItem() {
            this.currentItem = await this.getBaseItem();
            this.currentItem._editing = true;
        },
        saveRowItem(item) {
            console.log('detail save row', item)
            if(this.currentItem){
                if(this.currentItem._editing !== undefined){
                    this.currentItem._editing = false;
                }
            }
            item.is_new = false;
            item._editing = false;
            this.$emit('save:item', item);
            this.currentItem = null;

        },
        getCopyItem(item){
            return JSON.parse(JSON.stringify(item))
        }
    }
}