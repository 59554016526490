import { VCard, VCardText, VCardActions, VCardTitle, VSpacer, VIcon, VContainer, VRow, VCol, VBtn, VDialog } from 'vuetify/lib'; import Vue from 'vue'

export default {
    methods: {
        showModal(tmpl, data) {
            let vm = null;
            const Component = Vue.extend({
                data() {
                    return {
                        dialog: true,
                        content: ''
                    }
                },
                components: { VCard, VCardText, VCardTitle, VSpacer, VCardActions, VIcon, VContainer, VRow, VCol, VBtn, VDialog },
                mounted() {
                },
                props: ['item'],
                methods: {
                    close() {
                        vm.dialog = false;
                        setTimeout(() => {
                            vm.$destroy();
                        }, 10)

                    }
                },
                template: tmpl
            });
            vm = new Component({
                i18n: this.$i18n,
                propsData: data
            });
            vm.$vuetify = this.$vuetify;
            vm.$store = this.$store;
            vm.$mount();
        }
    }
}