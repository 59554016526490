import MixinConfirm from '@/components/mixins/MixinConfirm';

export default {
    mixins: [ MixinConfirm ],
    methods: {

        executeUpdate() {
            const values = this.getValues();
            let id = this.itemId || this.$route.params.id;
            if(this.item && this.item.id){
                id = this.item.id;
            }
            if (id) {
                this.$http.put(this.urlSave + '/' + id, values, true, true).then((response) => {
                    if(response.success){
                        this.setItem(response.item);
                        this.moduleActions = response.module_actions;
                    }
                });
            } else {
                this.$http.post(this.urlSave , values, true, true).then((response) => {
                    if(response.success){
                        this.setItem(response.item);
                        this.moduleActions = response.module_actions;
                    }
                });
            }
        },
        executeDelete(item){
            this.openConfirm(this.$t('Esta seguro que desea eliminar este registro?')).then((response) => {
                if(response){
                    this.$http.delete(this.urlGet + '/' + item.id, null, true, true).then((response) => {
                        if(response.success){
                            this.$router.go(-1);
                        }
                    });
                }
            });
        },
        executeCreate() {
            this.item = null;
            this.$router.push(this.pathCreate);
        }
    }

}