<template>
  <div>
    <AppMenu />
    <BaseBreadcrumb
      :title="$t(title)"
      :help="help"
      :breadcrumbs="breadcrumbs"
      :tutorial="tutorial"
      module="credit-note"
    >
      <template v-slot:actions>
        <div class="d-flex justify-end">
          <v-btn
            id="btn-credit-note-create"
            class="mr-2"
            small
            :color="'primary'"
            :title="$t('new')"
            @click="executeCreate()"
          >
            <v-icon small>mdi-plus</v-icon> {{ $t("new") }}
          </v-btn>
        </div>
      </template>
    </BaseBreadcrumb>
    <div class="py-2 mt-4 pl-3 pr-3">
      <v-row no-gutters>
        <v-col cols="12">
          <div class="d-flex flex-column justify-center">
            <Form
              @change:item="updateItemFromResponse"
              :item="item"
              :module-actions="moduleActions"
              ref="form"
              :url-save="urlSave"
              :url-get="urlGet"
            ></Form>
            <v-container fluid class="d-flex flex-column justify-center">
            </v-container>
          </div>
        </v-col>
        <v-col cols="12" md="4" class="px-2"> </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import Form from "./CreditNoteUpdateForm.vue";
import AppMenu from "../menu/AppMenu.vue";

export default {
  components: { Form, AppMenu },
  data() {
    return {
      title: "create_de-credit-note",
      urlGet: "api/v1/de-credit-note",
      urlSave: "api/v1/de-credit-note",
      pathBase: "/pos/de-credit-note",
      pathCreate: "/pos/de-credit-note/create",
      help: "",
      breadcrumbs: [],
      tutorial: "",
      item: null,
      pos: null,
      installation: null,
      moduleActions: [],
    };
  },
  mounted() {
    if (this.$store.getters.installation) {
      this.installation = this.$store.getters.installation;
    }
  },
  watch: {
    "$store.getters.installation"(value) {
      this.installation = value;
    },
    installation(value) {
      this.getPosInstallation();
    },
  },
  methods: {
    updateItemFromResponse(res) {
      this.item = { ...res.item };
    },
    async getPosInstallation() {
      if (!this.installation) {
        this.installation = await this.$db.adapter.getInstallation();
        this.$store.dispatch("setInstallation", this.installation);
      }
      if (this.installation) {
        const cashRegister = await this.$db.getCashRegister(
          this.installation.cash_id
        );
        this.pos = { id: cashRegister.id, name: cashRegister.name };
        this.getItem();
      }
    },
    getItem() {
      let id = this.$route.params.id;
      if (id) {
        this.$http.get(this.urlGet + "/" + id).then((res) => {
          if (res.success) {
            this.item = res.item;
            this.$refs.form.loadForm(this.item);
            this.itemToTitle();
          }
        });
      }
    },
    openHelp() {
      window.open(process.env.VUE_APP_BASECONFIG_URL_HELP, "_blank");
    },
    toogleDrawer() {
      this.$store.commit("toggle");
    },
    breadcrumbsInit() {
      this.breadcrumbs = [];
    },
    itemToTitle() {
      if (this.item.consecutive_credit_note) {
        this.title = "Nota Crédito No " + this.item.consecutive_credit_note;
      }
    },
  },
};
</script>
