<template>
  <v-card class="card_credit_note" style="background-color: white">
    <v-app-bar flat color="primary">
      <v-toolbar-title class="text-h6 white--text pl-0">
        {{ $t("Factura No. ") }}
        {{ item.invoice.name }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        color="white"
        icon
        @click="closeModalModifyCreditNote"
        :title="$t('Cancel')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>

    <v-card-text class="pa-0" style="max-height: 60vh; overflow-y: auto">
      <v-col cols="12" md="12" class="mb-0 pb-0">
        <v-alert
          :icon="$store.getters.mobile ? false : 'mdi-information-outline'"
          prominent
          text
          type="info"
          :class="
            $store.getters.mobile
              ? 'body-1 font-weight-regular text-justify'
              : ''
          "
        >
          A continuación, se listan los <strong>productos</strong> de la
          <strong>Factura</strong>.<br />
          Seleccione los que desea incluir en la
          <strong>Nota Crédito</strong>.<br>
          
        </v-alert>
      </v-col>

      <v-list
        class="list-mobile grey lighten-3 mx-4 my-0 py-0"
        subheader
        two-line
      >
        <template v-if="productsInvoice.length > 0">
          <template v-for="(itemDocument, index) in productsInvoice">
            <v-list-item
              :key="index"
              :id="'item-invoice-' + itemDocument.id"
              @click="selectItem(itemDocument)"
            >
              <v-list-item-content class="item-product">
                <v-list-item-title class="prd_title">
                  <span>{{ itemDocument.product.name }}</span>
                  <span
                    v-if="
                      itemDocument.product_variant &&
                      itemDocument.product_variant.name
                    "
                  >
                    - {{ itemDocument?.product_variant?.name }}</span
                  >
                </v-list-item-title>
                <v-row class="pt-1 pb-0">
                  <v-col cols="12" md="6" class="" :class="$store.getters.mobile ? 'pb-0' : ''">
                    <v-row>
                      <v-col cols="2">
                        <v-list-item-avatar>
                          <v-icon class="primary white--text" dark
                            >mdi-package-variant-closed-check</v-icon
                          >
                        </v-list-item-avatar>
                      </v-col>
                      <v-col cols="10">
                        <div>
                          {{ $t("Cantidad comprada") }}:<br />
                          <span class="primary--text font-weight-bold" small
                            >{{ itemDocument.quantity }}</span
                          >
                          x
                          <span class="primary--text font-weight-bold" small>{{
                            $filters.currency(itemDocument.item_amount, 0)
                          }}</span>
                        </div>
                        <div>
                          {{ $t("Monto total") }}:<br />
                          <span class="primary--text font-weight-bold">
                            {{
                              $filters.currency(itemDocument.total_amount, 0)
                            }}
                          </span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="6" :class="$store.getters.mobile ? 'pt-0' : ''">
                    <v-row>
                      <v-col cols="2">
                        <v-list-item-avatar>
                          <v-icon class="white--text" :class="getColorStatus(itemDocument)" dark v-if="getColorStatus(itemDocument) == 'red'"
                            >mdi-package-variant-closed-remove</v-icon
                          >
                          <v-icon class="white--text" :class="getColorStatus(itemDocument)" dark
                            v-else>mdi-package-variant-closed-minus</v-icon
                          >
                        </v-list-item-avatar>
                      </v-col>
                      <v-col cols="10">
                        <div>
                          {{ $t("Existencias devueltas") }}:<br />
                          <span :class="getColorStatus(itemDocument)+'--text font-weight-bold'" small dark
                            >{{ itemDocument.products_refund }} (disponibles {{ (itemDocument.quantity -itemDocument.products_refund) }})</span
                          >
                        </div>
                        <div>
                          {{ $t("Dinero reembolsado") }}:<br />
                          <span class="font-weight-bold" :class="getColorStatus(itemDocument)+'--text'" small>
                            {{ $filters.currency(itemDocument.money_refund, 0) }}
                          </span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-list-item-content>

              <!--v-list-item-action>
                <v-chip color="primary" class="mx-auto py-10" label>
                  
                  {{ $t("Reembolsado") }}<br />
                  {{ itemDocument.products_refund }} {{ itemDocument.product.unity.name }}<br />
                  Total:
                  {{ $filters.currency(itemDocument.money_refund, 0) }}
                  </v-chip
                >
                </v-list-item-action-->
              <v-list-item-action>
                <v-btn plain elevation="0">
                  <v-icon color="grey">mdi-chevron-right</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider
              v-if="index < productsInvoice.length - 1"
              :key="'doc_' + index"
            ></v-divider>
          </template>
        </template>
        <template v-else>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon class="blue white--text" dark
                >mdi-package-variant-closed</v-icon
              >
            </v-list-item-avatar>

            <v-list-item-content class="item-product">
              <v-list-item-title>
                <span>{{
                  $t("No hay productos disponibles para elegir")
                }}</span>
              </v-list-item-title>
              <v-list-item-subtitle>
                <span v-if="title" class="title-td">
                  {{ $t("Es probable que ya este seleccionado.") }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn plain elevation="0">
                <v-icon color="grey">mdi-chevron-right</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
    </v-card-text>
    <v-divider class="mt-2"></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        dark
        small
        color="secondary"
        @click="closeModalModifyCreditNote"
        :title="$t('Cancel')"
      >
        <v-icon>mdi-close-circle-outline</v-icon>{{ $t("Cancel") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style scoped>
.card_credit_note .prd_title {
  /*border-bottom: 1px solid #9e9e9e !important*/
}
</style>
<script>
import BaseFormPage from "@/components/mixins/BaseFormPage";
import MixinFormActions from "@/components/mixins/MixinFormActions";
import MixinModalDynamic from "@/components/mixins/MixinModalDynamic";
import NextMixinListPage from "@/components/mixins/NextMixinListPage";
import MixinActions from "@/components/mixins/MixinListActions";
//import MixinUtilSdCreditNote from "./MixinUtilSdCreditNote";
//import SdCreditNoteUpdate from "./SdCreditNoteUpdate";

export default {
  mixins: [NextMixinListPage, MixinActions],
  components: {},
  props: ["item", "itemsCreditNote", "openModalProductsInvoice"],
  data() {
    return {
      title: "Proveedores",
      form: {},
      url: "api/v1/provider-basic",
      pathBase: "/purchase/provider",
      options: {
        page: 1,
        itemsPerPage: 10,
        itemsPerPageOptions: [10, 25, 50, 100],
      },
      detailsSelected: [],
      productsInvoice: [],
    };
  },
  watch: {
    openModalProductsInvoice: {
      deep: true,
      handler() {
        if (this.openModalProductsInvoice) {
          this.getProductsInvoice();
        }
      },
    },
  },
  mounted() {
    this.getProductsInvoice();
  },
  methods: {
    closeModalModifyCreditNote() {
      this.productsInvoice = [];
      this.$emit("modal:close");
    },
    getProductsInvoice() {
      if (typeof this.item.invoice !== "undefined") {
        this.productsInvoice = [];
        this.$http
          .get(
            "api/v1/invoice-products-availables/" +
              this.item.invoice.id,
            null,
            true,
            true
          )
          .then((response) => {
            if (response.success) {
              for(let it of response.items){
                it._editing = false;
                if(it._id){
                  it.id = it._id;
                  delete it._id;
                }
                if(!it.id){
                    it.id = '_' + (new Date()).getTime();
                }
              }
              this.removeExistingDetail(response.items, this.itemsCreditNote);
            }
          });
      } else {
        this.productsInvoice = [];
      }
    },
    removeExistingDetail(itemsInvoice, itemsCreditNote) {
      for (const itemDt of itemsInvoice) {
        this.productsInvoice.push(itemDt);
      }

      if (itemsCreditNote.length > 0) {
        for (const itemCN of itemsCreditNote) {
          let resultIndex = this.productsInvoice.findIndex(
            (obj) => obj.id === itemCN.id
          );
          if (resultIndex !== -1) {
            this.productsInvoice.splice(resultIndex, 1);
          }
        }
      }
    },
    selectItem(item) {
      if(item.quantity_available <= 0){
        alert("De este producto/servicio ya se devolvierón todas las existencias disponibles, no es posible agregar.");
      }else{
        this.productsInvoice = [];
        item.quantity = item.quantity_available;
        item._editing = true;
        item.is_new = true;
        item.reverse_inventory = { id: "false", name: "No" };

        if (item.taxes && item.taxes.length > 0) {
          item.tax =  item.taxes[0];
        } else {
          item.tax = {
            id: 3,
            name: "Sin Impuesto",
            base: "100",
            percentage: "0",
            type: "IVA",
            tax_amount: 0
          };
        }
        
        item.rule = { id: 20076022, name: "Devoluciones en ventas" };
        const subtotal = (item.quantity * parseFloat(item.item_amount)) - (item.tax.tax_amount); 
        if(typeof item.line_extension_amount === "undefined") {
          item.line_extension_amount = subtotal;
        }        
        if(typeof item.subtotal_amount === "undefined") {
          item.subtotal_amount = subtotal;
        }
        if(typeof item.total_amount === "undefined") {
          item.total_amount = subtotal;
        }   
        
        if(item.tax && typeof item.tax.taxable_amount == undefined) {
          item.tax.taxable_amount = item.subtotal_amount;
        }   
        
        if (item.product) {
          if (item.product.unity && item.product.unity.id) {
            item.product.inv_unity_id = item.product.unity.id;
          }
        } 

        if (item.allowance_charges && item.allowance_charges.length > 0) {
          if(item.allowance_charges[0] == null){
            item.allowance_charges[0] = {
              charge_indicator: false,
              allowance_charge_reason: "DESCUENTO GENERAL",
              amount: 0,
              base_amount: item.line_extension_amount,
            }
          } else {
            if(typeof item.allowance_charges[0].base_amount == 'undefined') {
              item.allowance_charges[0].base_amount = item.line_extension_amount;
            }
            if(typeof item.allowance_charges[0].charge_indicator == 'undefined') {
              item.allowance_charges[0].charge_indicator = false;
            }
            if(typeof item.allowance_charges[0].allowance_charge_reason == 'undefined') {          
              item.allowance_charges[0].allowance_charge_reason = "DESCUENTO GENERAL";
            }    
            if(typeof item.allowance_charges[0].amount == 'undefined') {          
              item.allowance_charges[0].amount = 0;
            }             
          }                   
        }        
        this.$emit("modal:detailSend", item);
      }
      
    },
    getColorStatus(itemDocument){
      if(itemDocument.products_refund < itemDocument.quantity){
        return 'green';
      }
      else if(itemDocument.products_refund >= itemDocument.quantity){
        return 'red';
      }
    }
  },
};
</script>
