export default {
    methods: {
        closeEditModal() {
            this.dialogEdit = false;
            this.currentItem = null;
        },
        editModal(item) {
            this.edit(item);
            
            this.dialogEdit = true;
            setTimeout(() => {
                this.$refs.modalEditItem.loadFormItem();
            }, 250)
            
        },
        saveModalItemAndAdd() {
            this.currentItem._editing = false;
            this.addItemMobile();
        },
        saveModalItem(formModal) {
            //console.log(formModal)
            if(formModal){
                this.currentItem = formModal;
            }
            this.currentItem._editing = false;
            // si el item existe simplemente cierra la modal
           /* for (let i in this.items) {
                let item = this.items[i];
                if (item.id == this.currentItem.id) {
                    //item = this.currentItem;
                    this.closeEditModal();
                    return;
                }
            }
            this.items.push(this.currentItem);
            */
           // this.$emit("input", this.items);
            this.$emit('save:item', this.currentItem);
            this.closeEditModal();
        },
        async addItem() {
            if (!this.currentItem || this.isValid(this.currentItem)) {
                this.lastItem = this.currentItem;
                if (this.currentItem) {
                    this.currentItem._editing = false;
                }
                await this.createItem();
                this.dialogEdit = true;
            }
        }
    }
}