<template>
  <div>
    <div v-for="(item, index) in items" :key="index">
      <v-row no-gutters class="mt-4">
        <v-col cols="5" class="font-weight-medium subtitle-2">{{
          $t("Producto")
        }}</v-col>
        <v-col
          cols="7"
          class="font-weight-regular subtitle-2 text-right">
          <span v-if="item.product && item.product.name">{{ item.product.name }}</span>
          
        </v-col>

        <v-col cols="5" class="font-weight-medium subtitle-2">{{
          $t("Concepto")
        }}</v-col>
        <v-col
          cols="7"
          class="font-weight-regular subtitle-2 text-right">
          <span v-if="item.rule && item.rule.name">{{ item.rule.name }}</span>
        </v-col>
        <v-col cols="5" class="font-weight-medium subtitle-2">{{
          $t("Cantidad")
        }}</v-col>
        <v-col 
          cols="7"
          class="font-weight-regular subtitle-2 text-right"          
          >
                  <span v-if="item.quantity">{{ item.quantity }}</span>
                  <span v-if="item.product && item.product.unity && item.product.unity.name"> ({{ item.product.unity.name }})</span></v-col
        >

        <v-col cols="5" class="font-weight-medium subtitle-2">{{
          $t("Valor producto")
        }}</v-col>
        <v-col
          cols="7"
          class="font-weight-regular subtitle-2 text-right"
          
          ><span v-if="item.item_amount">{{ $filters.currency(item.item_amount, 0) }}</span></v-col
        >
        <v-col cols="5" class="font-weight-medium subtitle-2">{{
          $t("Base")
        }}</v-col>
        <v-col
          cols="7"
          class="font-weight-regular subtitle-2 text-right">
          <span v-if="item.line_extension_amount">{{ $filters.currency(item.line_extension_amount, 0) }}</span>
          </v-col>

        <template v-if="item.taxes">
          <v-col cols="5" class="font-weight-medium subtitle-2">{{
            $t("Impuesto")
          }}</v-col>
          <!--div >{{ $filters.currency(item.discount_amount) }} ( {{ item.percentage_discount }} % ) </div-->
          <v-col cols="7" class="font-weight-regular subtitle-2 text-right">
            <span v-if="item.taxes[0] && item.taxes[0].name">{{ item.taxes[0].name }} %</span>
          </v-col>
        </template>
        <template>
          <v-col cols="5" class="font-weight-medium subtitle-2">{{
            $t("Valor Impuesto")
          }}</v-col>
          <v-col cols="7" class="font-weight-regular subtitle-2 text-right"
            >{{ $filters.currency(item.tax_amount, 0) }}
          </v-col>
        </template>
        <template v-if="item.total_amount">
          <v-col cols="5" class="font-weight-medium subtitle-2">{{
            $t("Total Detalle")
          }}</v-col>
          <v-col cols="7" class="font-weight-regular subtitle-2 text-right"
            >{{ $filters.currency(item.total_amount, 0) }}
          </v-col>
        </template>
        <v-col cols="12" class="font-weight-medium subtitle-2">{{
          $t("Observaciones")
        }}</v-col>
        <v-col
          cols="12"
          class="font-weight-regular subtitle-2 text-right"
          v-if="item.observations"
          >{{ item.observations }}</v-col
        >
        <v-col cols="12">
          <div
            class="d-flex justify-space-around my-3"
            v-if="form && !disabled"
          >
            <v-btn small @click="editModal(item)" icon color="primary"
              ><v-icon>mdi-pencil</v-icon> {{ $t("Editar") }}
            </v-btn>
            <v-btn small @click="deleteItem(item)" icon color="error"
              ><v-icon>mdi-delete</v-icon> {{ $t("Eliminar") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </div>

    <div class="d-flex justify-end mt-4 mb-4" v-if="!disabled">
      <v-btn
        block
        small
        color="primary"
        :title="$t('Agregar Detalle')"
        @click="openModalProductsValid"
        :disabled="!allowAddItem()"
        ><v-icon>mdi-plus</v-icon> {{ $t("Agregar Detalle") }}</v-btn
      >
    </div>

    <v-dialog v-model="dialogEdit" persistent max-width="680">
      <DetailModalEdit
        @closeEditModal="closeEditModal"
        @saveModalItem="saveModalItem"
        @saveModalItemAndAdd="saveModalItemAndAdd"
        @save:item="saveRowItem"
        @addVariants="addVariants"
        :item="currentItem"
        :products-invoice="productsInvoice"
        ref="modalEditItem"
      ></DetailModalEdit>
    </v-dialog>

    <v-dialog v-model="openModalProductsDocument" persistent max-width="680">      
        <ModalProductsAvailablesDocumentSupport          
          :item="form"
          :itemsCreditNote="items"
          :openModalProductsDocument="openModalProductsDocument"
          @modal:close="closeModalProductsValid"
          @modal:detailSend="detailSendFromModalProductsAvailables"          
        ></ModalProductsAvailablesDocumentSupport>
    </v-dialog>




  </div>
</template>
<script>
import NextMixinDetails from "@/components/mixins/NextMixinDetails";
import NextMixinDetailsMobil from "@/components/mixins/NextMixinDetailsMobil";
import MixinDetailsCreditNote from "./MixinDetailsCreditNote";
import DetailModalEdit from "./DetailModalEdit.vue";
import ModalProductsAvailablesInvoice from "./ModalProductsAvailablesInvoice.vue";

export default {
  name: "NgDetailsMobileView",
  components: { DetailModalEdit, ModalProductsAvailablesInvoice },
  mixins: [
    NextMixinDetails, 
    NextMixinDetailsMobil, 
    MixinDetailsCreditNote
  ],
  data() {
    return {
      openModalProductsInvoice: false
    };
  },
  props: ["items", "form", "disabled", "productsInvoice"],
  watch: {
    items: {
      deep: true,
      handler() {
        this.calculateDocumentTotals();
      },
    },
  },
  methods: {
    edit(item) {
      item.tax = item.taxes[0];
      if (this.currentItem) {
        if (!this.isValid(this.currentItem)) {
          return;
        }
        this.currentItem._editing = false;
      }
      item._editing = true;
      this.currentItem = item;
    },
    openModalProductsValid(){
      this.openModalProductsInvoice = true;
    },
    closeModalProductsValid(){
      this.openModalProductsInvoice = false;
    },
    detailSendFromModalProductsAvailables(item){      
      this.$nextTick(() => {
        this.openModalProductsInvoice = false;          
        //this.edit(item);              
        this.$emit("add:item", item);        
      });
    }
  },
};
</script>
