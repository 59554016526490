import NgConfirm from './../base/NgConfirm';
import NgDialogText from './../base/NgDialogText';

import Vue from 'vue'

export default {
    methods:{
        openConfirm(msg){
            return new Promise((resolve, reject) => {
                const data = { text: msg };
                if(data){
                    data['resolve'] = resolve;
                }
                const NgConfirmFactory = Vue.extend(NgConfirm);
                const vm = new NgConfirmFactory({
                    i18n: this.$i18n,
                    propsData: data
                });
                vm.$vuetify = this.$vuetify;
                vm.$store = this.$store;
                vm.$mount();
            });
        },
        openDialogText(msg){
            return new Promise((resolve, reject) => {
                const data = { text: msg };
                if(data){
                    data['resolve'] = resolve;
                }
                const NgConfirmFactory = Vue.extend(NgDialogText);
                const vm = new NgConfirmFactory({
                    i18n: this.$i18n,
                    propsData: data
                });
                vm.$vuetify = this.$vuetify;
                vm.$store = this.$store;
                vm.$mount();
            });
        }
    }

}