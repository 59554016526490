<template>
  <v-row no-gutters>
    <v-col cols="12" class="panel-left-credit-note">
      <v-tabs show-arrows>
        <v-tab class="btn-credit-note-tab">
          {{ $t("general_info") }}
        </v-tab>
        <v-tab-item>
          <v-sheet outlined>
            <div class="d-flex flex-column justify-center">
              <ValidationObserver v-slot="{ invalid, errors }" ref="form">
                <v-container fluid>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-card elevation="0" class="px-2 py-4 no-shadow">
                        <v-row no-gutters>
                          <v-col
                            class="px-1 input-form-credit_note_date"
                            cols="12"
                            md="6"
                          >
                            <ng-date
                              v-model="form.credit_note_date"
                              label="credit_note_date"
                              :rules="rules['credit_note_date']"
                            ></ng-date>
                          </v-col>
                          <v-col
                            class="px-1 input-form-invoice"
                            cols="12"
                            md="6"
                          >
                            <ng-auto
                              v-model="form.invoice"
                              label="invoice"
                              :rules="rules['invoice']"
                              :disabled="!allowUpdate()"
                              @click:clear="resetDetails"
                              @change="resetDetails"
                              url="api/v1/lists/validated-invoice-de"
                            ></ng-auto>
                          </v-col>
                          <v-col
                            class="px-1 input-form-credit_note_discrepancy_responses"
                            cols="12"
                            md="6"
                          >
                            <ng-select
                              v-model="form.credit_note_discrepancy_responses"
                              label="credit_note_discrepancy_responses"
                              :rules="
                                rules['credit_note_discrepancy_responses']
                              "
                              :help="{
                                type: 'text',
                                value:
                                  '<ul> <li><b>Ajuste de precio:</b>  Cuando deseas modificar el valor de un producto o servicio.</li> <li><b>Anulación de factura electrónica:</b> Por motivos, como errores en la factura, cambios en los términos acordados o situaciones especiales, recuerda reversar completamente el inventario de todos los productos.</li> <li><b>Devolución parcial de los bienes y/o no aceptación parcial del servicio:</b> Cuando se devuelve solo una parte de los bienes adquiridos o rechaza parcialmente un servicio.</li> <li><b>Otros:</b> Algún motivo diferente a los de este listado.</li> <li><b>Rebaja o descuento parcial o total:</b> Se refiere a la reducción del precio, ya sea de manera parcial o total.</li> <ul>',
                              }"
                              url="api/v1/lists/credit-note-discrepancy-responses"
                            ></ng-select>
                          </v-col>
                          <v-col
                            class="px-1 input-form-observations"
                            cols="12"
                            md="12"
                          >
                            <ng-textarea
                              v-model="form.observations"
                              label="observations"
                              :rules="rules['observations']"
                              :disabled="!allowUpdate()"
                            ></ng-textarea>
                          </v-col>
                          <v-col
                            cols="12"
                            :class="$store.getters.mobile ? 'px-1' : 'px-4'"
                            class="title font-weight-regular"
                            >{{ $t("credit-note.details") }}</v-col
                          >
                          <v-col cols="12" class="px-1">
                            <div v-if="!$store.getters.mobile">
                              <FormDetailsDesktopView
                                :items="form.details"
                                :form="form"
                                :disabled="!allowUpdate()"
                                @add:item="addItemToDetails"
                                @save:item="saveRowItem"
                                :products-invoice="productsAvailables"
                              ></FormDetailsDesktopView>
                            </div>
                            <div v-else>
                              <FormDetailsMobileView
                                :items="form.details"
                                :form="form"
                                @add:item="addItemToDetails"
                                @save:item="saveRowItem"
                                :products-invoice="productsAvailables"
                                :disabled="!allowUpdate()"
                              ></FormDetailsMobileView>
                            </div>
                          </v-col>
                        </v-row>
                        <default-errors :errors="errors"></default-errors>
                        <v-row no-gutters>
                          <v-col cols="12">
                            <div class="py-2 mt-2 text-right">
                              <v-btn
                                small
                                :block="$store.getters.mobile"
                                :class="$store.getters.mobile ? 'mb-2' : ''"
                                color="secondary"
                                outlined
                                :title="$t('cancel')"
                                @click="cancel"
                                class="mr-2"
                              >
                                <v-icon small>mdi-close</v-icon>
                                {{ $t("cancel") }}
                              </v-btn>
                              <v-btn
                                id="btn-credit-note-create"
                                class="mr-2"
                                small
                                :block="$store.getters.mobile"
                                :class="$store.getters.mobile ? 'mb-2' : ''"
                                v-if="!item"
                                :disabled="invalid || disabledUpdate()"
                                :color="'primary'"
                                :title="$t('save_and_edit')"
                                @click="executeCreate()"
                              >
                                <v-icon small>mdi-content-save</v-icon>
                                {{ $t("save_and_edit") }}
                              </v-btn>
                              <v-btn
                                id="btn-credit-note-update"
                                class="mr-2"
                                small
                                :block="$store.getters.mobile"
                                :class="$store.getters.mobile ? 'mb-2' : ''"
                                v-if="
                                  item &&
                                  can(item._actions, 'update') &&
                                  allowUpdate()
                                "
                                :disabled="invalid || disabledUpdate()"
                                :color="'primary'"
                                :title="$t('update')"
                                @click="executeUpdate()"
                              >
                                <v-icon small>mdi-content-save</v-icon>
                                {{ $t("update") }}
                              </v-btn>
                              <v-btn
                                id="btn-credit-note-annulled"
                                class="mr-2"
                                small
                                :block="$store.getters.mobile"
                                :class="$store.getters.mobile ? 'mb-2' : ''"
                                v-if="
                                  item &&
                                  can(item._actions, 'annulled') &&
                                  allowUpdate()
                                "
                                :disabled="invalid"
                                :color="'warning'"
                                :title="$t('Anular')"
                                @click="executeAnnulled()"
                              >
                                <v-icon small>mdi-delete</v-icon>
                                {{ $t("Anular") }}
                              </v-btn>
                              <v-btn
                                id="btn-credit-note-draft"
                                class="mr-2"
                                small
                                :block="$store.getters.mobile"
                                :class="$store.getters.mobile ? 'mb-2' : ''"
                                v-if="
                                  item &&
                                  can(item._actions, 'send_to_drafts') &&
                                  allowUpdate()
                                "
                                :disabled="invalid"
                                :color="'warning'"
                                :title="$t('Enviar a paso anterior')"
                                @click="executeDraft()"
                              >
                                <v-icon small>mdi-pencil</v-icon>
                                {{ $t("Enviar a paso anterior") }}
                              </v-btn>
                              <v-btn
                                id="btn-credit-note-finalized"
                                class="mr-2"
                                small
                                :block="$store.getters.mobile"
                                :class="$store.getters.mobile ? 'mb-2' : ''"
                                v-if="
                                  item &&
                                  can(item._actions, 'finalized') &&
                                  allowUpdate()
                                "
                                :disabled="invalid"
                                :color="'primary'"
                                :title="$t('Aprobar')"
                                @click="executeFinalized()"
                              >
                                <v-icon small>mdi-check</v-icon>
                                {{ $t("Aprobar") }}
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </ValidationObserver>
            </div>
          </v-sheet>
        </v-tab-item>
      </v-tabs>
    </v-col>
    <v-col cols="12" class="pl-0 pl-md-3 mt-3 mt-md-0 panel-right-credit-note">
    </v-col>
  </v-row>
</template>
    <script>
import MixinConfirm from "@/components/mixins/MixinConfirm";
import MixinForm from "./MixinForm";
import FormDetailsDesktopView from "./FormDetailsDesktopView.vue";
import FormDetailsMobileView from "./FormDetailsMobileView.vue";

export default {
  mixins: [MixinConfirm, MixinForm],
  components: { FormDetailsDesktopView, FormDetailsMobileView },
  props: ["item", "urlSave", "urlGet"],

  data() {
    return {
      rules: {},
      form: {},
    };
  },

  mounted() {
    this.setRules();
    this.clearForm();
  },
  methods: {
    clearForm() {
      this.form = {
        credit_note_date: null,
        invoice: null,
        credit_note_discrepancy_responses: null,
        observations: null,
        details: null,
      };
    },
    setRules() {
      this.rules = {
        credit_note_date: "required",
        invoice: "required",
        credit_note_discrepancy_responses: "required",
        observations: "required",
        details: "required",
      };
    },
    getValues() {
      let values = {};
      if (
        !this.item ||
        this.hasChanged(
          this.item["credit_note_date"],
          this.form["credit_note_date"]
        )
      ) {
        values["credit_note_date"] = this.form["credit_note_date"];
      }
      if (
        !this.item ||
        this.hasChanged(this.item["invoice"], this.form["invoice"])
      ) {
        values["invoice"] = this.form["invoice"]
          ? this.form["invoice"].id
          : null;
      }
      if (
        !this.item ||
        this.hasChanged(
          this.item["credit_note_discrepancy_responses"],
          this.form["credit_note_discrepancy_responses"]
        )
      ) {
        values["credit_note_discrepancy_responses"] = this.form[
          "credit_note_discrepancy_responses"
        ]
          ? this.form["credit_note_discrepancy_responses"].id
          : null;
      }
      if (
        !this.item ||
        this.hasChanged(this.item["observations"], this.form["observations"])
      ) {
        values["observations"] = this.form["observations"];
      }
      if (
        !this.item ||
        this.hasChanged(this.item["details"], this.form["details"])
      ) {
        values["details"] = this.form["details"];
      }

      return values;
    },
  },
};
</script>
