export default {
  methods: {

    allowAddItem() {
      if (
        !this.form ||
        !this.form.invoice ||
        !this.form.invoice.id
      ) {
        return false;
      }
      return true;
    },
    // async addItem() {
    //   if (!this.currentItem || this.isValid(this.currentItem)) {
    //     this.lastItem = this.currentItem;
    //     if (this.currentItem) {
    //       this.currentItem._editing = false;
    //     }
    //     await this.createItem();
    //     console.log("se ejecuta este", this.currentItem)
    //     this.dialogEdit = true;
    //   }
    // },
    isValid(item) {

      if (!item) {
        return false;
      }
      if (!item.product || !item.product.id) {
        return false;
      }
      if (item.quantity == "" || item.quantity == null) {
        return false;
      }
      if (!item.subsidiary_target || !item.subsidiary_target.id) {
        return false;
      }
      if (!item.warehouse_target || !item.warehouse_target.id) {
        return false;
      }
      return true;
    },
    async addVariants(product) {
      // console.log('addv-prd', product);
      let i = 0;
      for (const v of product.variantsSelected) {
        //console.log('addv-var', v);
        if (i == 0 && this.currentItem) {
          let pr = { name: "" };
          if (product) {
            pr = {
              id: product.id,
              name: product.name /*category: product.category,*/,
              reference: product.reference,
              unity: product.unity,
              product_type: product.product_type,
              has_inventory: product.has_inventory,
            };
          }
          let vr = { name: "" };
          if (v) {
            vr = { id: v.id, name: v.name };
          }

          if (!this.currentItem.id) {
            this.currentItem.id = "_" + new Date().getTime();
          }
          // console.log('curr-it-old', this.currentItem); 
          this.currentItem.product = pr;
          this.currentItem.product_variant = vr;
          this.currentItem.quantity = v.quantity;
          this.currentItem.base_amount = 0;


          this.currentItem.item_amount = v.purchase_price;
          this.currentItem.total_amount = (v.purchase_price * v.quantity),

            this.currentItem.taxes = v.taxes ? [v.taxes] : null;

          if (this.currentItem.taxes && this.currentItem.taxes.length > 0) {
            this.currentItem.tax = this.currentItem.taxes[0];
          } else {
            this.currentItem.tax = null;
            this.currentItem.tax_amount = null;
          }

          let discount_amount = 0;
          this.currentItem.discount_amount = discount_amount;
          let line_extension_amount = (v.purchase_price * v.quantity);
          this.currentItem.line_extension_amount = line_extension_amount;
          let cost = line_extension_amount / parseFloat(v.quantity);
          this.currentItem.cost = cost;
          this.currentItem.subtotal_amount = (line_extension_amount - discount_amount);

          let tax_real_discount = this.calculateTaxes(
            this.currentItem.tax,
            this.currentItem.discount_amount
          );
          this.currentItem.real_discount = this.currentItem.discount_amount + tax_real_discount;

          let allowance_charges = [
            {
              charge_indicator: false,
              allowance_charge_reason: "DESCUENTO GENERAL",
              amount: discount_amount,
              base_amount: line_extension_amount,
            },
          ];

          let tax_amount = this.round(
            this.calculateTaxes(this.currentItem.tax, this.currentItem.subtotal_amount)
          );
          let subtotal_amount_roundOut = this.round(this.currentItem.subtotal_amount);

          this.currentItem.tax["tax_amount"] = tax_amount;
          this.currentItem.tax["taxable_amount"] = subtotal_amount_roundOut;
          this.currentItem.tax_amount = tax_amount;

          this.currentItem.allowance_charges = allowance_charges;
          this.currentItem._editing = false;
          // console.log('curr-it-new', this.currentItem);  
          if (!this.exists(this.currentItem)) {
            this.items.push(this.currentItem);
            this.$emit("input", this.items);
          }
          this.lastItem = this.currentItem;
        } else {
          const item = await this.getBaseItem(product, v);
          this.items.push(item);
          this.$emit("input", this.items);
        }
        i++;
      }
      if (this.closeEditModal) {
        this.closeEditModal()
      }
    },
    async createItem() {
      this.currentItem = await this.getBaseItem();
      this.currentItem._editing = true;
      this.$emit('consult-products');
    },
    calculateTaxes(tax, base) {
      if (tax) {
        if (tax["percentage"] > 0) {
          return (base * tax["percentage"]) / 100;
        }
      }
      return 0;
    },
    round(num) {
      let m = Number((Math.abs(num) * 100).toPrecision(15));
      return (Math.round(m) / 100) * Math.sign(num);
    },

    getBaseItem(product, variant) {
      console.log('bit-prd', product);
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          let sub = null;
          let warehouse = null;
          let quantity = variant ? variant.quantity : null;
          this.index = "_" + new Date().getTime();
          let pr = { name: "" };
          if (product) {
            pr = {
              id: product.id,
              name: product.name,
              category: product.category,
              unity: product.unity,
              product_type: product.product_type,
              has_inventory: product.has_inventory,
              reference: product.reference,
            };
          }
          let vr = { name: "" };
          if (variant) {
            vr = { id: variant.id, name: variant.name };
          }
          let purchase_price = variant && variant.purchase_price ? variant.purchase_price : 0;
          let tax_amount = variant && variant.tax_amount ? variant.tax_amount : 0;
          let line_extension_amount = variant && variant.line_extension_amount ? variant.line_extension_amount : 0;
          let taxes = variant && variant.taxes ? variant.taxes : null;
          if (!taxes) {
            taxes = product && product.taxes ? product.taxes : null;
          }
          const item = {
            id: this.index,
            // id: "",
            product: pr,
            product_variant: vr,
            rule: {},
            quantity: quantity,
            item_amount: purchase_price,
            total_amount: (purchase_price * quantity),
            discount_amount: 0,
            tax_amount: tax_amount,
            taxes: taxes,
            line_extension_amount: line_extension_amount,
            base_amount: ((purchase_price * quantity) - tax_amount),
            allowance_charges: null,
            // subsidiary_target: sub,
            // warehouse_target: warehouse,
            // _deleted: false,   
            is_new: true,
            _editing: false,
          };
          resolve(item);
        }, 10);
      });
    },

  }
}