<template>
  <div>
    <v-simple-table class="">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left body-2 font-weight-medium">
              {{ $t("Producto") }}
            </th>
            <th class="text-left body-2 font-weight-medium">
              {{ $t("Concepto") }}
            </th>
            <th class="text-left body-2 font-weight-medium">
              {{ $t("Detalle") }}
            </th>
            <th class="text-left body-2 font-weight-medium">
              {{ $t("Reversar Inventario") }}
            </th>
            <th class="text-left body-2 font-weight-medium">
              {{ $t("Cantidad") }}
            </th>
            <!--th class="text-left body-2 font-weight-medium" style="width: 10%">{{ $t("Base") }}</th-->
            <!--th class="text-left body-2 font-weight-medium" style="width: 10%">{{ $t("V.Impuesto") }}</th-->
            <th class="text-left body-2 font-weight-medium">
              {{ $t("Precio Venta") }}
            </th>
            <th class="text-left body-2 font-weight-medium">
              {{ $t("Impuesto") }}
            </th>
            <th class="text-left body-2 font-weight-medium">
              {{ $t("Total") }}
            </th>
            <th style="width: 90px"></th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, index) in items">
            <tr :key="index">
              <td v-if="item._editing" :colspan="9">
                <DetailRowEdit
                  @addVariants="addVariants"
                  @delete="deleteItem(item)"
                  @cancel="cancelEdit(item)"
                  @save:item="saveRowItem"
                  :item="getCopyItem(item)"
                  :products-invoice="productsInvoice"
                ></DetailRowEdit>
              </td>
              <template v-else>
                <td class="font-weight-regular">
                  <span>{{ item?.product?.name }}</span>
                  <br>
              <span v-if="item.product_variant"
                ><b>{{ item?.product_variant?.name }}</b></span
              >
                </td>
                <td class="font-weight-regular">
                  <span>{{ item?.rule?.name }}</span>
                </td>
                <td class="font-weight-regular">
                  <span>{{ item.observations }}</span>
                </td>
                <td class="font-weight-regular">
                  <span
                    v-if="item.reverse_inventory && item.reverse_inventory.name"
                    >{{ item?.reverse_inventory?.name }}</span
                  >
                </td>
                <td class="font-weight-regular">
                  <span v-if="item.quantity">{{ item.quantity }}</span>
                  <span v-if="item.product && item.product.unity"> ({{ item?.product?.unity?.name }})</span>
                </td>
                <!--td>
              <span>{{ $filters.currency(item.line_extension_amount) }}</span>
            </td>
            <td>
              <span>{{ $filters.currency(item.tax_amount) }}</span>
            </td-->
                <td class="font-weight-regular">
                  <span>{{ $filters.currency(item.item_amount) }}</span>
                </td>
                <td class="font-weight-regular">
                  <span v-if="item.taxes && item.taxes[0]">{{
                    item.taxes[0].name
                  }}</span>
                </td>
                <td class="font-weight-regular">
                  <span>{{ $filters.currency(item.total_amount) }}</span>
                </td>
                <td>
                  <div v-if="form && !disabled">
                    <v-btn
                      @click="edit(item)"
                      x-small
                      icon
                      :title="$t('edit')"
                      color="primary"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      @click="deleteItem(item)"
                      x-small
                      icon
                      :title="$t('delete')"
                      color="error"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </td>
              </template>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>

    <!-- agregar estas clases-->
    <div class="d-flex justify-end my-3" v-if="!disabled">
      <v-btn
        small
        color="primary"
        :title="$t('Agregar Detalle')"
        @click="openModalProductsValid" 
        :disabled="!allowAddItem() || itemCurrentlyBeingEdited()"
        ><v-icon>mdi-plus</v-icon> {{ $t("Agregar Detalle") }}</v-btn
      >
    </div>

    
    <v-dialog v-model="openModalProductsInvoice" persistent max-width="680">      
        <ModalProductsAvailablesInvoice          
          :item="form"
          :itemsCreditNote="items"
          :openModalProductsInvoice="openModalProductsInvoice"
          @modal:close="closeModalProductsValid"
          @modal:detailSend="detailSendFromModalProductsAvailables"          
        ></ModalProductsAvailablesInvoice>
    </v-dialog>

    
  </div>
</template>
<script>
import DetailRowEdit from "./DetailRowEdit.vue";
import NextMixinDetails from "@/components/mixins/NextMixinDetails";
import MixinDetailsCreditNote from "./MixinDetailsCreditNote";
import ModalProductsAvailablesInvoice from "./ModalProductsAvailablesInvoice.vue";

export default {
  name: "NgDetailsDesktopView",
  mixins: [NextMixinDetails, MixinDetailsCreditNote],
  components: { DetailRowEdit, ModalProductsAvailablesInvoice },
  data() {
    return {
      openModalProductsInvoice: false
    };
  },
  props: ["items", "form", "disabled", "products-invoice"],
  methods: {
    // esta funcion edit se agrega en el controlador
    edit(item) {
      item.tax = item.taxes[0];
      if (this.currentItem) {
        if (!this.isValid(this.currentItem)) {
          return;
        }
        this.currentItem._editing = false;
      }
      item._editing = true;
      this.currentItem = item;
      // this.$emit('consult-products');
    },
    openModalProductsValid(){
      this.openModalProductsInvoice = true;
    },
    closeModalProductsValid(){
      this.openModalProductsInvoice = false;
    },
    detailSendFromModalProductsAvailables(item){      
      this.$nextTick(() => {
        this.openModalProductsInvoice = false;  
        this.edit(item);              
        this.$emit("add:item", item);        
      });
    }
  },
};
</script>
